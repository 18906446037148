import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../assets/css/researcher.css"
import ReactMarkdown from "react-markdown"



const ResearchTeamPage = ({data}) => {
  //javascript
  const title = 'Research Team';
  const metatitle = 'Research Team';
  const description = 'Meta Description for The Research Team';
  const metadescription = description;
  function LinkRenderer(props) {
    return <a href={props.href} target="_blank">{props.children}</a>
  }
  return(
    <Layout bodyclass="research-team" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <ul>
      {data.allStrapiResearchers.nodes.map(researcher=>{
        
        return(
          <li key={researcher.id} className="researcher">
            <figure>
              <Img fluid={researcher.ProfileImage.childImageSharp.fluid} />
            </figure>
            <div className="bio">
              <h2>{researcher.Name}</h2>
              <ReactMarkdown 
              source={researcher.Bio}
              renderers={{link: LinkRenderer}}
              />
            </div>
          </li>
        )
        })}
      </ul>
      
      
      
    </Layout>
  )
}

export default ResearchTeamPage

export const query = graphql`
  query Researcher {
    allStrapiResearchers {
      nodes {
        Bio
        Name
        id
        ProfileImage {
          childImageSharp {
            fluid(maxWidth: 240) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`